import { Atom, Ctrl, state } from ":mods";
import { ShowResourcesViewProps } from "../model";
import "../css/resources.css";
import { For, Match, Show, Switch, createEffect, createSignal, splitProps } from "solid-js";
import { nonAdminApis } from "../apis";

export function ShowResources(props: ShowResourcesViewProps) {
  const [local, others] = splitProps(props, ["$"]);
  const stage_id = local.$.actions.getStageSelected();
  const $list = state.createAsync([nonAdminApis.listGet, stage_id]);
  local.$.actions.setLayout({
    title: "Resources",
  });
  createEffect(() => {
    if ($list.state.success) {
      const title = $list.unwrap.value.data.title;
      if (title) {
        local.$.actions.updateLayout({
          title,
        });
      }
    }
  });

  return (
    <Switch>
      <Match when={$list.state.loading}>
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Taining Resource"
            msg="Loading stage modules..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      </Match>
      <Match when={$list.state.failed}>an error occurred while fetching {$list.error}</Match>
      <Match when={$list.state.success}>
        <div {...others} id="edit-rsrcs-main-container" class={`${others.class ?? ""}`}>
          <p class="main-description py-7 w-80%">
            {$list.value?.data?.description ??
              "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere delectus labore adipisci possimus laudantium recusandae libero pariatur quibusdam quos quidem ex suscipit voluptas nisi, commodi laboriosam facilis. Similique, molestiae explicabo?"}
          </p>
          <Show
            when={$list.value.data.resources && $list.value.data.resources.length > 0}
            fallback={"there are no schedules at the moment!."}
          >
            <For each={$list.value.data.resources}>
              {(item, index) => {
                console.log("item :: ", item);
                return (
                  <Atom.Card.Content
                    // id={item.id}
                    title={item.name}
                    desription={item.description}
                    image={item.image}
                    btnTitle="View resource"
                    onClick={() => {
                      window.open(item.link, "_blank");
                    }}
                  />
                );
              }}
            </For>
          </Show>
        </div>
      </Match>
    </Switch>
  );
}
